//plugins

/*exValidation*/
//吹き出し
.errMsg{margin-left:1em; padding-bottom:1px; display:block; line-height:1; text-indent:-.9em;}
.formError{
padding-bottom:5px; display:block; margin-left:-20px;
	@include for-sp(){
		margin-left:-50px;
	}
	.formErrorContent{
		padding:4px 6px; width:auto; position:relative; color:#fff; background:$RED; font-size:1.1rem; _z-index:1;
		border-radius: 3px;
	}
	.formErrorArrow{	width:20px; height:20px; position:absolute; bottom:-10px; left:3pxpx; 	_z-index:0; 
		div{	margin:0 auto; display:block; height:1px; background:$RED; line-height:0px; font-size:0px; }
		.line10{	width:19px;} 
		.line9{	width:17px;} 
		.line8{	width:15px;} 
		.line7{	width:13px;} 
		.line6{	width:11px;} 
		.line5{	width:9px;} 
		.line4{	width:7px;} 
		.line3{	width:5px;} 
		.line2{	width:3px;}
		.line1{	width:1px;} 
	}
	.fadeOut{opacity: 0.2;}
}
.err,.chkgroup.err input,.chkgroup.err select{
	border-color:$RED !important;
}
#fpurpose.err{
	position: relative;
	&:before{
		content:"";width: calc(100% + 10px);height: calc(100% + 20px);
		position: absolute;top:0;left:-10px;
		border: 1px solid $RED;
	}
	input[type="text"]{border-color: #999 !important;}
}
