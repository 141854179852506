//page

#login,#register{
	background: url(../images/bg.jpg) center top no-repeat $BLACK;background-size:1366px 832px;
	@include for-sp(){
		background-size:683px 416px;
	}
}

#login,#register,#faq{
	header{
		height: 200px;padding-top: 40px;
		@include for-sp(){
			padding-top: 10px;height: 90px;
		}
	}
	.logo{
		width: 119px;
		@include for-sp(){
			width: 70px;
		}
	}
	.head-navi{
		right:50px;top:50px;display: flex;	
		@include for-tb(){
			right:25px;
		}
		@include for-sp(){
			width: 100%;font-size: 1.1rem;
			right:0;top:40px;
			justify-content: space-between;
			li{
				margin-left: 0;
				a{
					padding: 0 20px;
				}
			}
		}
	}
	article{
		padding-top: 20px;
	}
}


#login{
	
	.login-tit{
		font-size: 2.4rem;text-align: center;line-height: 1.3;
		color: #fff;
		letter-spacing: 0.2em;text-indent: 0.2em;
		@include for-sp(){
			font-size: 1.8rem;
		}
	}
	.login-box{
		width: 400px;margin: 80px auto 0;
		@include for-sp(){
			width: 100%;margin-top: 50px;
		}
	}
	.login-tb{
		margin-top: 40px;
		th,td{
			padding: 12px 0;
		}
		th{width: 145px;padding-top: 18px;}
	}
	.login-btn{
		text-align: center;margin-top: 24px;
	}
	.login-password-remind{
		text-align: center;font-size: 1.3rem;margin-top: 24px;
	}
	
	.reminder-tit{
		font-size: 2.8rem;text-align: center;
		@include for-sp(){
			font-size: 2.1rem;
		}
	}
	.reminder-text{
		text-align: center;
		@include for-sp(){
			text-align: left;
		}
	}
	.reminder-tb{
		width: 900px;margin:0 auto;
		@include for-tb(){
			width: 100%;
		}
		th{width: 280px;}
	}
	hr{
		width: 200px;margin-left: auto;margin-right: auto;
	}
}


.info-list{
	@include for-sp(){
		width: 100vw;margin-left: -10vw;
	}
	li{
		border-bottom: 1px solid #363636;
	}
	a{
		display: block;padding: 15px 40px 15px 20px;
		position: relative;
		transition:background $hover,color $hover;
		@include for-sp(){
			padding: 10px 30px 10px 20px;
		}
		&:hover{
			background: #222;text-decoration: none;
			color: #999;
		}
		&:before{
			content:"";width: 10px;height: 17px;
			background: url(../images/icon_arrow.png) no-repeat;background-size:cover;
			position: absolute;right:15px;top:calc(50% - 8.5px);
			@include for-sp(){
				width: 7px;height: 12px;top:calc(50% - 6px);
			}
		}
		&.nolink{
			pointer-events: none;
			&:before{display: none;}
		}
	}
	&-date{
		font-size: 1.3rem;line-height: 1;display: block;
		@include for-sp(){
			font-size: 1rem;
		}
	}
	&-tit{
		line-height: 1.5;display: block;margin-top: 5px;
		@include for-sp(){
			font-size: 1.3rem;
		}
	}
}


#register{
	hr{
		width: 200px;margin-left: auto;margin-right: auto;
	}
	.register-text{
		text-align: center;
		@include for-sp(){
			text-align: left;
		}
	}
	.register-check{
		display: flex;align-items: center;
		width: 900px;margin: 40px auto 0;
		border: 1px solid #363636;padding: 35px 0;
		@include for-tb(){
			width: 100%;flex-direction: column-reverse;
		}
		@include for-sp(){
			border: none;padding: 0;
		}
		&-left{
			width: 400px;text-align: center;border-right: 1px solid #363636;
			@include for-tb(){
				width: 100%;padding-top: 30px;
				border-right:none;border-top: 1px solid #363636;
			}
		}
		&-right{
			width: calc(100% - 400px);
			padding: 0 40px;
			@include for-tb(){
				width: 400px;padding: 0 0 40px;
			}
			@include for-sp(){
				width: 100%;
			}
		}
		&-tit{
			font-size: 1.5rem;
		}
		&-image{
			margin-top: 20px;
			@include for-sp(){
				width: 240px;margin: 10px auto 0;
			}
		}
		&-tb{
			th{letter-spacing: 0.05em;}
			th,td{
				padding: 9px 0;
			}
			th{
				padding-top: 15px;
			}
			@include for-sp(){
				.input-notice{
					display: inline-block;
				}
			}
		}
		&-input{
			width: 174px;margin-right: 10px;letter-spacing: 0.4em;text-indent: 0.4em;padding-left: 12px;
		}
		&-btn{
			margin-top: 24px;
		}
		&-caution{
			display: flex;width: 900px;margin: 60px auto 0;
			@include for-tb(){
				width: 100%;
			}
			@include for-sp(){
				display: block;
			}
		}
		&-caution-tit{
			padding-left: 60px;width: 290px;
			font-size: 2.4rem;
			background: url(../images/icon_check_caution.png) 0 12px no-repeat;background-size:51px;
			@include for-tb(){
				width: 220px;line-height: 1.2;font-size: 2.1rem;padding-right: 10px;
				background-position: 0 5px;
			}
			@include for-sp(){
				width: 100%;font-size:1.8rem;padding:0 0 5px 45px;
				background-position: 0 6px;background-size:36px;
			}
			.s{
				font-size: 1.4rem;line-height: 1;display: inline-block;
				@include for-tb(){
					line-height: 1.2;
				}
				@include for-tb(){
					font-size: 1.2rem;
				}
			}
			
		}
		&-caution-list{
			width: calc(100% - 290px);margin-top: 10px;font-size: 1.4rem;
			@include for-tb(){
				font-size: 1.3rem;width: calc(100% - 220px);
			}
			@include for-sp(){
				width: 100%;font-size: 1.2rem;
			}
			li{
				padding-left: 20px;line-height: 1.8;margin-top: 10px;
				&:before{
					content:"・";margin-left: -20px;width: 20px;display: inline-block;
				}
				&:first-child{margin-top: 0;}
			}
		}
	}
	
	.register-select{
		display: flex;
		//justify-content: space-between;
		margin-top: 60px;
		flex-flow: column;
		align-items: center;
		@include for-tb(){
			display: block;
		}
		&-box{
			width: 470px;border: 1px solid $BDC;padding: 36px 36px 110px;position: relative;
			background: $BLACK;
			@include for-tb(){
				width: 560px;margin: 0 auto;padding-bottom: 36px;
			}
			@include for-sp(){
				width: 100%;padding: 24px 24px 0;
			}
		}
		&-tit{
			text-align: center;padding-top: 85px;
		}
		&-text{
			font-size: 1.5rem;line-height: 1.8;margin-top: 32px;
			@include for-sp(){
				margin-top: 16px;font-size: 1.4rem;
			}
		}
		&-list{
			font-size: 1.5rem;line-height: 1.5;margin-top: 20px;
			@include for-sp(){
				font-size: 1.3rem;
			}
			li{
				margin-top: 10px;padding-left: 1.2em;position: relative;
				&:before{
					content:"■";position: absolute;left:0;top:0;color: #999;
				}
			}
		}
		&-btn{
			position: absolute;left:36px;bottom:20px;width: calc(100% - 72px);
			@include for-tb(){
				width: 100%;position: static;margin-top: 32px;
			}
			.btn{
				width: 100%;
				@include for-sp(){
					width: calc(100% + 48px);margin-left: -24px;
				}
			}
		}
		&-popup{
			display: flex;align-items: center;justify-content: center; width: 92px;height: 92px;border-radius: 50%;
			position: absolute;left:calc(50% - 46px - 120px);top:-46px;
			background: $YELLOW;color: $BLACK;font-size: 1.8rem;font-weight: 500;
			@include for-sp(){
				width: 68px;height: 68px;left:calc(50% - 34px - 100px);
				font-size: 1.4rem;
			}
			&:before{
				content:"";width: 18px;height: 18px;background: $YELLOW;
				transform: skew(40deg) rotate(17deg);
				position: absolute;bottom:6px;right:6px;z-index: 1;
			}
		}
		&-tb{
			font-size: 1.6rem;
			@include for-tb(){
				font-size: 1.5rem;
			}
			@include for-sp(){
				font-size: 1.2rem;line-height: 1.3;
				width: 100vw;margin-left: -10vw;
			}
			th,td{
				padding: 14px 10px;border-bottom: 1px solid $BDC;	border-left: 1px solid $BDC;
				vertical-align: middle;
				&:first-child{
					border-left: none;
				}
				@include for-sp(){
					padding: 10px;
				}
			}
			thead{
				th{
					background: #363636;
				}
			}
			tbody{
				td{
					text-align: center;
				}
			}
		}
		.product-user{
			border: none;
			padding: 36px 36px 90px;
			//width: 350px;
			@include for-tb(){
				margin-top: 0;
			}
			@include for-sp(){
				//width: 85%;
				padding: 24px 24px 0;				
			}
			.btn{
				font-size: 1.4rem;
				margin: 0 auto;
				width: 90%;
				@include for-sp(){
					width: calc(100% + 48px);
					margin: initial;
					margin-left: -24px;
					font-size: 1.2rem;
				}
				&::after{
					width: 14px;
				}
				.btn-break{
					display: block;
					padding-top: 5px;
				}
				.btn-label{
					text-align: center;
				}
			}
		}
		
	}
	.box-wide{
		width: 100%;
	}
	.mypage-user{
		.register-select-tit{
			background: url(../images/icon_mypage_user.png) center top no-repeat;background-size:150px 70px;
		}
		@include for-sp(){
			padding: 0;
		}
		.box-wrap{
			@include for-sp(){
				padding: 24px 24px 0;
			}
		}
		.register-select-btn{
			display: grid;
			place-content: center;
			@include for-tb(){
				place-content: unset;
			}
			.btn{
				width: 500px;
				@include for-tb(){
					width: 100%;
				}
				@include for-sp(){
					margin-left: 0;
				}
			}
		}
	}
	.product-user{
		@include for-tb(){
			margin-top: 40px;
		}
		.register-select-tit{
			background: url(../images/icon_product_user.png) center top no-repeat;background-size:80px 70px;
		}
	}
	.register-mail-tb{
		width: 700px;margin:0 auto;
		@include for-tb(){
			width: 100%;
		}
		th{width: 280px;
			@include for-tb(){
				width: 230px;
				.s{
					font-size: 1.2rem;
				}
			}
		}
	}
	
	.register-input-tb{
		width: 900px;margin: 0 auto;
		@include for-tb(){
			width: 100%;
		}
		th{width: 250px;position: relative;
			@include for-tb(){
				width: 180px;
			}
			@include for-sp(){
				width: 100%;
			}
		}
		
	}
	.register-product{
		
		
		
	}
	.banner-change{
		width: 720px;margin: 80px auto 0;font-size: 1.8rem;
		@include for-tb(){
			width: 100%;font-size: 1.7rem;margin-top: 60px;
		}
		@include for-sp(){
			font-size: 1.4rem;line-height: 1.5;
		}
		a{
			height: 100px;display: flex;justify-content: center;align-items: center;
			border: 1px solid $BDC; background: $BLACK;text-align: center;
			transition:background $hover;
			&:hover{
				text-decoration: none;background: #383838;
			}
		}
	}
	
	.change-usertype-tb{
		width: 700px;
		@include for-tb(){
			width: 100%;
		}
	}
	
}

.register-product{
	margin: 60px 0;border-top: 1px solid $BDC;border-bottom: 1px solid $BDC;padding: 40px 0;
	@include for-sp(){
		margin: 30px 0;
	}
	&-tit{
		font-size: 2.4rem;
		@include for-sp(){
			font-size: 2.1rem;
		}
	}
	&-box{
		background: #363636;padding: 20px 30px;margin-top: 16px;
		@include for-sp(){
			padding: 5px 15px;
		}
	}
	&-text{
		margin-top: 20px;
		@include for-sp(){
			margin-top: 10px;
		}
	}
	&-tb{
		th,td{
			padding: 8px 0 !important;
		}
		th{
			padding-top: 14px !important;width: 250px;
			@include for-tb(){
				width: 140px !important;
			}
		}
		tr{
			padding: 8px 0 !important;
		}
	}
	&-btn{
		margin-top: 10px;
		@include for-sp(){
			display: flex;flex-direction: column;align-items: flex-start;
			.btn-delete{
				margin-top: -15px;
			}
		}
	}
}
.register-product-list{
	background: $BLACK;border: 1px solid $BDC;padding: 10px;
	display: block;width: 900px;
	opacity: 0;visibility: hidden;
	position: absolute;right:0;top:50px;z-index: 10;
	@include for-tb(){
		width: calc(80vw - 60px);height: 320px;overflow-x:hidden;
	}
	@include for-sp(){
		width: calc(96vw - 30px);height: 250px;padding: 5px;
	}
	&.is-show{
		opacity: 1;visibility: visible;
		@include for-sp(){
			right:-8vw;
		}
	}
	ul{
		display: flex;flex-wrap: wrap;
		li{
			display:flex;align-items: center; cursor: pointer;padding: 7px !important;width: 25%;margin-top: 0 !important;
			@include for-tb(){
				width: 33%;
			}
			@include for-sp(){
				width: 50%;padding: 4px !important;
			}
			&:before{display: none !important;}
			&:hover{
				background: #363636;
			}
		}
	}
	
	.register-product-thumb{
		display:flex;align-items: center;width: 40px;height: 40px;background: #fff;
		position: relative;
	}
	.register-product-label{
		display:flex;align-items: center;
		width: calc(100% - 40px);padding-left: 10px;font-size: 1.2rem;line-height: 1.3;
		@include for-sp(){
			font-size: 1rem;padding-left: 5px;
		}
	}
	.register-product-image{
		position: absolute;left:50%;bottom:40px;transform: translateX(-50%);z-index: 999;
		width: 250px;height: 250px;border: 2px solid $BLACK;
		img{
			width: 100%;height: 100%;object-fit:cover;
		}
	}
}



#faq{
	.faq-tit{
		font-size: 3.2rem;text-align: center;
		@include for-sp(){
			font-size: 2.1rem;
		}
	}
	.faq-subtit{
		margin-bottom: 20px;
	}
	
	.faq-box{
		border-bottom: 1px solid #363636;margin-top: 0;
	}
	.faq-q{
		display: flex;align-items: center;padding-right: 40px;color: #fff;
		position: relative;transition:background 0.2s ease,color 0.2s ease;
		cursor: pointer;
		@include for-sp(){
			display: block;padding-right: 25px;
		}
		.icon-faq{
			display: inline-block;font-size: 2.8rem;width: 40px;height: 50px;text-align: center;line-height: 1.5;
			@include for-sp(){
				font-size: 2.1rem;height: auto;text-align: left;
			}
		}
		.faq-q-text{
			display: block;width: calc(100% - 40px);padding: 10px;line-height: 1.5;
			@include for-sp(){
				padding: 5px 0 10px;width: 100%;font-size: 1.4rem;
			}
		}
		&:before{
			content:"";width: 12px;height: 12px;border-right:1px solid $BDC;border-bottom: 1px solid $BDC;
			position: absolute;right:10px;top:50%;transform:translateY(-50%) rotate(45deg);
			transition:all 0.2s ease;
		}
		&:hover{
			background: #222;color: #999;
			@include for-sp(){
				background: none;color: inherit;
			}
			&:before{
				border-color: #fff;top:calc(50% + 5px);
			}
		}
		&.faq-open{
			&:before{
				transform:translateY(-50%) rotate(45deg) scale(-1,-1);
			}
		}
	}
	.faq-a{
		display: none;
		padding: 10px 0 20px 50px;
		font-size: 1.5rem;line-height: 1.8;
		@include for-sp(){
			padding: 0 0 10px 0;font-size: 1.3rem;
		}
	}
}


#top{
	.top-info-tit{
		font-size: 2.4rem;
		@include for-sp(){
			font-size: 1.9rem;
		}
		.s{
			font-size: 1.9rem;margin: 0 3px;
			@include for-sp(){
				font-size: 1.6rem;
			}
		}
	}
}


#item{
	.register-product{
		border: none;padding: 0;
	}
	.item-add-btn{
		display: flex;justify-content: flex-end; margin-top: -40px;
		@include for-tb(){
			margin-top: -50px;
			.btn{
				width: 300px;
			}
		}
		@include for-sp(){
			margin-top: 20px;
		}
	}
	.item-list-tb{
		@include for-tb(){
			font-size: 1.4rem;
		}
		
		th,td{
			vertical-align: middle;
		}
		.th4{
			width: 140px;
			@include for-tb(){
				width: 110px;
			}
		}
		.th5{
			width: 100px;
			@include for-tb(){
				width: 70px;
			}
		}
		tbody td:last-child{text-align: center;
			@include for-tb(){
				padding: 16px 0;
			}
			@include for-sp(){
				padding: 0;width: 45px;
				position: absolute;right:10px;bottom:20px;
			}
		}
		.list-check{text-align: center;
			@include for-tb(){
				padding: 16px 0;
			}
			@include for-sp(){
				padding: 0;width: 20px;
				position: absolute;left:20px;top:10px;
			}
		}
		@include for-sp(){
			width: 100vw;margin-left: -10vw;
			thead{display: none;}
			tr{
				position: relative;padding: 15px 20px;
			}
			.list-check + th{
				padding-left: 25px !important;font-size: 1.3rem;
			}
			td[data-label]{
				font-size: 1.1rem;padding-left: 25px !important;
				width: calc(100% - 60px);
				&:before{
					content:attr(data-label) "：";
				}
			}
		}
	}
	
}

#product{
	.register-product{
		border: none;padding: 0;
	}
}

#user{
	.user-input-tb{
		tbody th{
			width: 250px;
		}
	}
}

#mail{
	.mail-input-tb{
		tbody th{
			width: 250px;
		}
	}
}

#information{
	.info-detail-tit{
		h2{
			font-size: 2.4rem;line-height: 1.3;
			@include for-sp(){
				font-size: 1.8rem;
			}
		}
	}
	.info-detail-date{
		font-size: 1.4rem;color: #999;margin-top: 10px;
		@include for-sp(){
			font-size: 1.2rem;
		}
	}
	
	.info-detail-image{
		width: 768px;margin: 40px auto;
		@include for-tb(){
			width: 100%;
		}
		img{
			max-width: 100%;height: auto;
		}
	}
}

