//form
$FBDC:#999;

input,button,textarea,select{}
input[type="button"],input[type="submit"],button{
	font-family:inherit;appearance: none; margin: 0;cursor: pointer;color: #fff;
}
input[type="text"],input[type="number"],input[type="tel"],input[type="password"],input[type="email"],textarea{
	appearance: none; border: 1px solid $FBDC;
	font-size: 1.6rem;font-family:sans-serif;background: #282828;border-radius:0;	
	transition:background 0.2s linear;color: #fff;
	&:focus{
		background: #383838;
	}
}
input[type="text"],input[type="number"],input[type="tel"],input[type="password"],input[type="email"]{
	padding:0 8px; width:100%; height: 40px;
	@include for-sp(){
		padding:0 5px;
	}
	&:read-only{
		background: none;border: none;
	}
	
}
textarea{
	padding:8px; width:100%; height: 10.5em; line-height: 1.5;	
	@include for-sp(){
		padding:5px;
	}
	&:focus{
		background: #383838;
	}
}


@include for-sp(){
	
}

.input-notice{
	font-size: 1.2rem;
	@include for-tb(){
		display: block;margin-top: 3px;
	}
	@include for-sp(){
		font-size: 1rem;
	}
}


//テキストボックス

.input-box{
	display: inline-block;
	position: relative;
	.placeholder{
		color: #555;
		position: absolute;left:8px;top:6px;z-index: 1;
		transition:all 0.15s ease;
	}
	input:focus + .placeholder,input:not(:placeholder-shown) + .placeholder{
		color: $FONT_COLOR;font-size: 1.1rem;top:-18px;
		@include for-sp(){
			font-size: 1rem;
		}
	}
}

//セレクトボックス
.select-wrap{
	position: relative; display: inline-block;max-width: 100%;background: #282828;
	&:after{
		content:"›";color: #fff;position: absolute;right:8px;top:calc(50% - 0.5em);z-index: 1;
		line-height: 1;
		transform: rotate(90deg);z-index: 1;font-size: 1.4rem;
		@include for-sp(){
			right:5px;
		}
	}
	&:before{
		content:"";width: 1px;height: 100%;position: absolute;right:25px;top:0;background: $FBDC;
		@include for-sp(){
			right:19px;
		}
	}
	
}

select{
	margin: 0;max-width: 100%;font-family:sans-serif;
	appearance: none;height: 40px;line-height: 40px;border: 1px solid $FBDC;
	padding: 0 33px 0 8px;position: relative;z-index: 2;background: none;
	font-size: 1.6rem;
	@include for-sp(){
		padding:0 25px 0 5px;
	}
	option{
		background: #282828;
	}
	&:focus{
		background: rgba(#fff,0.07);
	}
}
select::-ms-expand { display: none;}


//ラジオボタン
input[type="radio"],input[type="checkbox"]{margin-right: 5px;}

.radio {
  display: inline-block; cursor: pointer;position: relative;line-height: 1;padding: 8px 5px 8px 27px;
  margin-right: 30px;margin-top: 0 !important;font-size: 1.6rem;
  @include for-sp(){
		font-size: 1.4rem;
	}
  input {
    opacity: 0;appearance: none;position: absolute;
    &:checked + .outer .inner {
      opacity: 1;transform: scale(1);
    }
    &:checked + .outer {  }
    &:focus + .outer .inner {
      opacity: 0.5;
    }
    &:disabled + .outer{
			background: $BLACK;border-color: #363636;
    }
  }
  
  // Outer ring of custom radio
  .outer {
		width: 20px;height: 20px;position: absolute;top:6px;left:0;margin: 0;
    display: block;border: 1px solid $FBDC;  border-radius: 50%;
    transition:border 0.2s linear;background: #282828;
  }
  
  // Inner ring of custom radio
  .inner {
		width: 6px;height: 6px;display: block;
		border-radius: 50%; background-color: #fff;opacity: 0;
		position: absolute;left:6px;top:6px;
		overflow: hidden;text-indent: -9999px;
		transition:opacity 0.1s linear,transform 0.3s $ease9;
		transform: scale(0.7);
  }
  
  &:hover{
	 .outer{border-color: rgba($FBDC,0.5);} 
  }
}
.win .radio{
	padding: 13px 5px 13px 27px;
	.outer{
		top:11px;
	}
}
.checkbox {
  display: inline-block; cursor: pointer;position: relative;line-height: 1;padding: 8px 5px 8px 27px;
  margin-right: 30px;margin-top: 0 !important;font-size: 1.6rem;
  @include for-sp(){
		font-size: 1.4rem;
	}
  input {
    opacity: 0;appearance: none;position: absolute;
    &:checked + .outer .inner {
      opacity: 1;transform: scale(1) rotate(-45deg);
    }
    &:checked + .outer {  }
    &:focus + .outer .inner {
     opacity: 0.5;
    }
    &:disabled + .outer{
			background: $BLACK;border-color: #363636;
    }
  }
  
  // Outer ring of custom radio
  .outer {
		width: 20px;height: 20px;position: absolute;top:6px;left:0;margin: 0;
    display: block;border: 1px solid $FBDC; border-radius: 2px;background: #282828;
    transition:border 0.2s linear;
  }
  
  // Inner ring of custom radio
  .inner {
		width: 10px;height: 7px;display: block;
		opacity: 0;border-left:2px solid #fff;border-bottom:2px solid #fff;
		position: absolute;left:4px;top:3px;
		overflow: hidden;text-indent: -9999px;
		transition:opacity 0.1s linear,transform 0.3s $ease9;
		transform: scale(0.7) rotate(-45deg);
  }
  
  &:hover{
	 .outer{border-color: rgba($FBDC,0.5);} 
  }
}
.win .checkbox{
	padding: 13px 5px 13px 27px;
	.outer{
		top:11px;
	}
}

.req{
	font-weight: 500;font-size: 1.1rem;margin-left: 10px;line-height: 1;border-radius: 3px;letter-spacing: 0;
	display: inline-block;padding: 4px 6px;background: $RED;color: $FONT_COLOR;
	@include for-sp(){
		font-size: 1rem;margin-left: 5px;border-radius: 1px;padding: 2px 4px 2px 3px;
	}
}


.register-name,.register-kana{
	.input-group{
		width: 280px;
		@include for-sp(){
			margin-top: 15px;
		}
	}
	.input-box{
		width: 120px;margin-left: 20px;
		&:first-of-type{
			margin-left: 0;
		}
	}
}

.register-address{
	.input-box{
		display: flex;align-items: center;margin-top: 20px;
		@include for-tb(){
			display: block;
		}
		&:first-of-type{
			margin-top: 0;
		}
		label{
			width: 160px;font-size: 1.5rem;
			@include for-tb(){
				display: block;margin-bottom: 5px;
			}
			@include for-sp(){
				font-size: 1.2rem;margin-bottom: 3px;
			}
		}
		input{
			max-width: calc(100% - 160px);
			@include for-tb(){
				max-width:100%;
			}
		}
	}
	#fpostal-code{
		width: 120px;
	}
}

.register-birthday,.register-product-date{
	.select-wrap{margin-right: 5px;
		@include for-sp(){
			margin-right: 3px;
		}
	}
}

.register-melmag{
	@include for-tb(){
		font-size: 1.4rem;
	}
	td>div{
		margin-top: 20px;
	}	
}
.register-product-name, .refresh_service_product{
	td{
		position: relative;
	}
}
.checkbox-list{
	display: flex;flex-wrap: wrap;
	li{
		width: 33%;margin-top: 15px;
		@include for-sp(){
			width: 50%;margin-top: 10px;
		}
		&.long{
			width: 100%;
			label{margin-right: 10px;}
		}
	}
}
.chkgroup{
	display: inline-block;
}
#fpassword,#fckpassword{
	width: 300px;
	@include for-tb(){
		width: calc(100% - 80px);
	}
}

#ftel{
	width: 180px;
}
#fpurpose-other,#fpurpose-other-sports{
	width: 300px;
	@include for-tb(){
		width: 250px;
	}
	@include for-sp(){
		width: 100%;
	}
}

:placeholder-shown {color: #555; }
::-webkit-input-placeholder {color:#555; }
::-moz-placeholder {color: #555; opacity: 1; }
:-ms-input-placeholder {color:#555; }
