
$FONT_FAMILY:YakuHanMP_Noto,'Noto Serif JP', serif;

$FONT_SIZE:1.7rem;
$LINE_HEIGHT:2.0;
$FONT_COLOR:#E2E2E2;

$RED:#D93732;
$BLUE:#0000ff;
$GRAY:#999;
$BLACK:#181818;
$YELLOW:#FFF500;


$BDC:#888;

$BGC_GRAY:#eee;

$BREAK_PC:1280px;
$BREAK_TAB:959px;
$BREAK_SP:559px;


$ease1:cubic-bezier(.445, .05, .55, .95);
$ease2:cubic-bezier(.455, .03, .515, .955);
$ease3:cubic-bezier(.645, .045, .355, 1);
$ease4:cubic-bezier(.72,0,.14,1);
$ease5:cubic-bezier(.25, .46, .45, .94);
$ease6:cubic-bezier(.8,.11,.65,.96);
$ease7:cubic-bezier(.25,.48,.63,.93);
$ease8:cubic-bezier(.92,.01,.74,.99);
$ease9:cubic-bezier(.1,.82,.1,1);


$hover: 0.3s $ease7;