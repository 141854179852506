//module
a{text-decoration: none;transition: opacity $hover;cursor: pointer;
	&:hover{text-decoration: underline;
		img{opacity:0.7; transition: opacity $hover;}
	}
}

.tbdisp{display: none;}
.spdisp{display: none;}

@include for-tb(){
	.tbdisp{display: block;}
	.tbhide{display: none;}
}

@include for-sp(){
	.spdisp{display: block;}
	.sphide{display: none;}
}


// clearfix
.cf:after{content:"";display:block;	clear:both;}

.center {	text-align: center;}
.right {	text-align: right;}
.left {	text-align: left;}
.fl-left {	float: left;}
.fl-right {	float: right;}
.cl{clear:both;}

.btn{
	width: 400px;height: 60px;display: flex;	align-items: center;justify-content: center;
	color: #fff;font-weight: 500;line-height: 1;position: relative;
	background: #292D31;overflow: hidden;
	transition:all 0.2s linear;
	@include for-tb(){
		background: #c9c9c9;color: $BLACK;
	}
	@include for-sp(){
		width: 100%;height: 50px;
	}
	.btn-label{
		position: relative;z-index: 2;white-space: nowrap;
	}
	&:before{
		content: "";width: 100%;height: 100%;background: $BGC_GRAY;
		position: absolute;left:-100%;top:0;
		transition: left 0.5s $ease9;
		@include for-tb(){
			display: none;
		}
	}
	&:after{
		content:"";width: 36px;height: 1px;background: #ddd;
		position: absolute;right:0;top:calc(50% - 0.5px);
		transition: transform 0.2s $ease1;transform-origin: right center;
		@include for-tb(){
			display: none;
		}
	}
	&:hover,&:focus{
		color: $BLACK;text-decoration: none;
		&:before{
			left:0;
		}
		&:after{
			transform: scaleX(0);
		}
	}
	&.btn-cancel{
		background: none;color: $BDC;border: 1px solid $BDC;
		&:before,&:after{display: none;}
		&:hover,&:focus{
			opacity: 0.7;
		}
	}
	&.btn-back{
		font-size: 1.4rem;width: 260px;height: 45px;
		&:after{
			right:auto;left:0;
		}
		@include for-sp(){
			font-size: 1.2rem;width: 100%;height: 32px;background: #999;
		}
	}
}

.btn-option{
	padding: 5px 10px;font-size: 1.5rem;background: #292D31;line-height: 1;
	transition:all 0.2s linear;display: inline-block;
	@include for-tb(){
		background: #aaa;color: $BLACK;
	}
	@include for-sp(){
		font-size: 1.3rem;padding: 5px 8px;
	}
	&:hover,&:focus{
		background: $BGC_GRAY;color: $BLACK;text-decoration: none;
		@include for-tb(){
			background: #aaa;color: $BLACK;
		}
	}
	&.view-password{
		font-size: 1.4rem;padding: 5px;margin-left: 10px;
		@include for-sp(){
			font-size: 1.3rem;margin-left: 5px;letter-spacing: 0.05em;
		}
	}
	&.btn-add{
		position: relative;padding-left: 25px;
		&:before{
			content:"+";line-height: 1;font-size: 1.5rem;
			position: absolute;left:10px;top:50%;transform: translateY(-50%);
		}
	}
	&.btn-delete{
		position: relative;padding-left: 25px;
		&:before{
			content:"×";line-height: 1;font-size: 1.2rem;
			position: absolute;left:8px;top:50%;transform: translateY(-50%);
		}
	}
}

.btn-area{
	display: flex;justify-content: center;align-items: center; margin-top: 50px;
	>*{
		margin: 0 20px;
	}
	@include for-sp(){
		flex-direction: column-reverse;margin-top: 30px;
		>*{
			margin: 15px 0 0;
		}
	}
}

.link{
	position: relative;color: #999;
	transition:color 0.2s linear;
	&:before{
		content:"";width: 100%;height: 1px;background: #999;
		position: absolute;left:0;bottom:0;
		transform: scaleX(0);transform-origin: right center;
		transition:transform 0.2s $ease1;
	}
	&:hover{
		color: #ccc;text-decoration: none;
		&:before{
			transform: scaleX(1);transform-origin: left center;
		}
	}
}

.arrow{

}

//フォントサイズ
.l{font-size:1.8rem;display: inline-block;}
.s{font-size:1.4rem;display: inline-block;}
.xs{font-size:1.2rem;display: inline-block;}

@include for-sp(){
	.xs{font-size: 1.0rem;}
	.s{font-size: 1.2rem;}
	.l{font-size: 1.6rem;}
}


//フォントカラー
.red{	color: $RED;}





.block{
	margin-top: 80px;
	
	@include for-sp(){
		margin-top: 40px;
	}
	
	&:first-child{
		margin-top: 0;
	}
	>* + *{margin-top:2em;}
	
	h2,h3,h4,h5,h6{line-height: 1.4;color: #fff;font-weight: 500;}
	h2{
		font-size: 3.2rem;
		@include for-sp(){
			font-size: 2.1rem;
		}
	}
	h3{
		font-size: 1.9rem;
		@include for-sp(){
			font-size: 1.7rem;
		}
	}
	h4{
		font-size: 1.7rem;
		@include for-sp(){
			font-size: 1.6rem;
		}
	}
	h5{
		
	}
	h6{
	
	}
	
	ul:not([class]){
		li{padding-left: 15px;position: relative;margin-top: 0.5em;
			&:before{content:"";width: 4px;height: 4px;display: block;background: $BLACK;
				position: absolute;left:2px;top:calc(0.9em - 2px);
			}
		}
	}
	ol:not([class]){
		li{margin-top: 5px;}
	}
	
	img{max-width: 100%;height: auto;}
	
	.mypage-tit{
		font-size: 2.1rem;
		.s{
			font-size: 1.8rem;
		}
	}
	
	.register-tit{
		font-size: 3.2rem;text-align: center;
		@include for-sp(){
			font-size: 2.1rem;
		}
	}

}

.box{
	border: 1px solid $BDC;padding:1.5em;
	@include last-mb0();
	> * + *{margin-top:1em;}
}



//表組み
table{
	width: 100%;font-size: $FONT_SIZE;line-height: 1.5;
	th,td{padding:24px 0;vertical-align: top;text-align: left;}
	th{ font-weight: 500; color: #fff;}
	thead th{ text-align: center;}
	.input-tr{
		th{
			padding-top: 30px;
		}	
	}
	&.list-tb{
		tr{
			&:nth-child(2n){background: #222;}
		}
		th,td{
			border: 1px solid #363636;padding: 16px 10px;
		}
		thead th{
			background: #444;padding: 8px 0;font-size: 1.4rem;
		}
		.list-check{
			vertical-align: middle;padding: 0;text-align: center;width: 50px;padding-top: 5px;
			@include for-tb(){
				width: 36px;
			}
			.checkbox{
				margin: 0;padding: 0;
				.outer{
					position: static;display: inline-block;
				}
				.inner{
					left:5px;top:5px;
				}
			}
		}
	}
}
.status-list{
	margin-top: 0;
	li{
		display: grid;
		grid-template-columns: 100px 1fr;
		gap: 10px;
		margin-top: 1em;
		@include for-sp(){
			grid-template-columns: 1fr;
			gap: 0;
			font-size: 1.2rem;
		}
		span{
			font-weight: 600;
		}
	}
}
.list-option-btn{
	margin-top: 10px;
}

@include for-sp(){
	table{
		font-size: 1.4rem;
		&:not(.nobreak){
			th,td{display: block;border: none;padding: 0 !important;width: 100%;}
			tr{display: block;border-bottom: 1px solid #363636;padding: 16px 0;
				&:last-child{border: none;	}
			}
			td{margin-top: 5px;}
		}
	}
}


//罫線
hr{	border: none;border-bottom: 1px solid #363636;margin: 50px auto;}


//ページネーション
.paginate{
	padding-top: 64px;width: 960px;margin: 0 auto;	
	@include for-tb(){
		width: 100%;
	}
}
.paginate-text{
	text-align: center;font-size: 1.2rem;color: #999;
	@include for-sp(){
		font-size: 1rem;
	}
}
.paginate-list{
	display: flex;justify-content: center;flex-wrap: wrap;
	
	
	li{
		width: 42px;padding-left: 0;margin: 0;
		@include for-sp(){
			width: 30px;
		}
		&:before{display: none;}
	}
	a{
		display: block;text-align: center;line-height: 36px;height: 36px;letter-spacing: 0;
		color: $FONT_COLOR;
		transition:background $hover;
		@include for-sp(){
			font-size: 1.2rem;line-height: 28px;height: 28px;	
		}
		&:hover{
			background:#383838;text-decoration: none;
		}
		
	}
	.current{
		a{background: #282828;pointer-events: none;}
	}
}



.modal{
	position: fixed;z-index: 1000;top:0;left:0;
	width: 100vw;height: 100vh;
	background: rgba(#000,0.7);opacity: 0;	visibility: hidden;
	transition:all 0.5s ease;
	&.modal-open{
		opacity: 1;visibility: visible;
	}
	&-window{
		width: 800px;background: #181818;padding: 80px 60px;border-radius: 25px;
		position: absolute;left:50%;top:50%;transform: translate(-50%,-50%);
		box-shadow: rgba(#fff,0.5) 0 0 30px 0;
	}
	
	&-tit{
		text-align: center;font-size: 2.1rem;
	}
	&-text{
		margin-top: 1.5em;
	}
}

.modal-register-check{
	.modal-text{
		text-align: center;
	}
	.btn{
		width: 300px;
	}
}

.complete-box{
	width: 700px;margin: 60px auto 0;
	border: 1px solid $BDC;padding: 60px;
	@include for-tb(){
		width: 100%;
	}
	@include for-sp(){
		padding: 25px;
	}
	.complete-tit{
		font-size: 2.4rem;text-align: center;margin-bottom: 30px;
		@include for-sp(){
			font-size: 1.8rem;
		}
	}
	.complete-text{
		text-align: center;
	}
	>p{
		margin-top: 1.5em;
	}
}

.bn-password_edit_send,.bn-mail_edit_complete{
	.complete-box{
		.complete-text{
			text-align: left;
		}
	}
}

.error-box{
	width: 700px;margin: 0 auto;
	@include for-tb(){
		width: 100%;
	}
	.error-tit{
		font-size: 2.1rem;
	}
	.error-text{
		text-align: center;
		@include for-tb(){
			text-align: left;margin-top: 1.5em;
			font-size: 1.3rem;
		}
	}
	
	/*.error-list{
		@include for-tb(){
			font-size: 1.3rem;
		}
		li{
			padding-left: 15px;position: relative;line-height: 1.5;margin-top: 15px;
			&:before{
				content:"・"; width: 15px;
				position: absolute;left:0;top:0;
			}
			
		}
	}*/
}

#mypage{
	.error-box{
		margin: 60px auto 0;
		padding: 30px 0 60px;
		@include for-tb(){
			margin: 0 auto;
		}
		.error-tit{
			font-size: 2.4rem;text-align: center;margin-bottom: 30px;
			padding-top: 50px;position: relative;
			@include for-tb(){
				font-size: 2.1rem;
			}
			&:before{
				content:"！";display: flex;justify-content: center;align-items: center; width: 40px;height: 40px;border: 1px solid $RED;font-size: 1.8rem;
				position: absolute;left:50%;top:0;transform: translateX(-50%);color: $RED;
			}
		}
		>p{
			margin-top: 1.5em;
		}
	}
}


.anm{
	position: relative;top:50px;opacity: 0;transition:top 0.7s $ease9 0.1s,opacity 0.4s linear 0.1s;
	&.is-show{
		top:0;opacity: 1;
	}
}

.paginate{
	p{
		margin-bottom: 15px;
	}
	ul{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		li{
			margin: 0 5px;
			padding-left: 0;
		}
	}
	.current{
		a{
			background: #292d31;
		}
	}
	a{
		border: 1px solid #292d31;
		color: #fff;
		display: block;
		padding: 5px 0 6px;
		min-width: 25px;
		text-align: center;
		line-height: 1;
		font-size: 1.2rem;
		&:hover{
			background: #292d31;
			color: #fff;
			border-style: solid;
			text-decoration: none;
		}
	}
}
.square{
	padding-left: 1.2em;
	position: relative;
	&:before{
		content: "■";
		position: absolute;
		left: 0;
		top: 0;
		color: #999;
	}
}
.mt-05{
	margin-top: 0.5em;
}