//layout

// ラッパー
#wrapper{
	width: 100%;min-width: 1000px;
}

@include for-tb(){
	#wrapper{
		min-width: 0;
	}
}

// ヘッダー
header{
	position: relative;text-align: center;
}

.logo{
	width: 59px;display: inline-block;padding-top: 12px;	padding-bottom: 12px;
	@include for-sp(){

	}
	img{@include max(w);}
}

.head-navi{
	position: absolute;right:30px;top:35px;
	font-size: 1.3rem;line-height: 1;
	display: flex; justify-content: flex-end;
	@include for-tb(){
		right:25px;font-size: 1.2rem;display: none;
	}
	li{
		margin-left: 50px;
		&.logout{
			padding-left: 25px;border-left: 1px solid $BDC;
		}
		a{
				display: inline-block;padding: 5px 0;color: #fff;
		}
		@include for-tb(){
			margin-left: 25px;
		}
	}
}
// ユーザー名・ID表示
.user-info {
    position: absolute;
    right: 30px;
    top: 15px;
    font-size: 1.3rem;
    line-height: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    font-size: 1.3rem;
	@include for-tb(){
		position: initial;
		display: block;
		right: auto;
    	top: auto;
		width: 80%;
		margin: 0 auto;
		line-height: 1.5rem;
		text-align: left;
		font-size: 1.2rem;
	}
}
// ID改行
.tbdisp-id{
	@include for-tb(){display: block;}
}
// グローバルナビ
#menu-btn{
	display: none;
}
@include for-tb(){
	#menu-btn{
		display: block;width: 60px;height: 60px;
		position: fixed;right:15px;top:10px;cursor: pointer;z-index: 999;overflow: hidden;
		&:before{
			content:"MENU"; color: #fff;font-size: 1rem;letter-spacing: 0.05em;width: 100%;
			position: absolute; bottom:5px;left:50%;transform: translateX(-50%);
		}
		.menu-line{
			display: block;width: 30px;height: 1px;
			background: #fff;position: absolute;left:calc(50% - 15px);
			&:nth-child(1){top:17px;transition: top 0.1s $ease2 0.2s,transform 0.2s $ease2 0.05s;}
			&:nth-child(2){top:24px;transition: transform 0.1s $ease2 0s;}
			&:nth-child(3){top:31px;transition: top 0.1s $ease2 0.2s,transform 0.2s $ease2 0.05s;}
		}
	}
	
	#menu.open{
		#menu-btn{
			.menu-line{
				&:nth-child(1){top:24px;transform: rotate(-135deg);transition: top 0.1s $ease2 0.05s,transform 0.2s $ease2 0.1s;}
				&:nth-child(2){transform: scaleX(0);}
				&:nth-child(3){top:24px;transform: rotate(135deg);transition: top 0.1s $ease2 0.05s,transform 0.2s $ease2 0.1s;}
			}
			&:before{
				content:"CLOSE";
			}
		}
		.navi-wrap{visibility: visible;opacity: 1;}
	}
	.navi-wrap{
		display: block;visibility: hidden;opacity: 0;transition: all 0.5s $ease5;
		width: 100%;height: 100%;padding: 60px 0;background: rgba(#444,0.9);backdrop-filter:blur(3px);
		overflow: auto;	-webkit-overflow-scrolling:touch;
		position: fixed;z-index: 998;top:0;left:0;
		
	}
}
@include for-sp(){
	#menu-btn{
		right:5px;
	}
	.navi-wrap{
		padding: 40px 0;
	}
}

.navi-wrap{
	width: 100%;border-top: 1px solid $BDC;border-bottom: 1px solid $BDC;
}
.gnavi{

}
.gnavi-list{
	display: flex;justify-content: center;font-size: 1.6rem;line-height: 1;
	@include for-tb(){
		display: block;
	}
	@include for-sp(){
		font-size: 1.5rem;
	}
	li{
		padding-left: 10px;position: relative;
		@include for-tb(){
			margin-top: 30px;padding-left: 0;
		}
		@include for-sp(){
			margin-top: 15px;
		}
		&:before{
			content:"/";color:#666;position: absolute;left:0;top:13px;
			@include for-tb(){
				display: none;
			}
		}
		&:first-child{
			padding-left: 0;
			&:before{display: none;}
		}
	}
	a{
		display: block;padding: 14px;position: relative;
		cursor: pointer;transition:color $hover;
		@include for-tb(){
			padding: 10px 0;
		}
		&:before{
			content:"";width: 9px;height: 9px;border-radius: 50%;
			background: #fff;position: absolute;left:calc(50% - 4.5px);bottom:-5px;
			transition:transform $hover,background $hover;transform: scale(0);
			@include for-tb(){
				display: none;
			}
		}
		&:hover{
			text-decoration: none;color: #999;
			&:before{
				background: $BDC;transform: scale(1);
			}
		}
	}
	.active a{
		&:before{
			background: #fff;transform: scale(1);
		}
	}
}

.snavi-list{
	display: none;
	@include for-tb(){
		display: block;line-height: 1;
		li{margin-top: 30px;}
		a{
			color: $FONT_COLOR;display: block;padding: 10px 0;
			&:before{
				display: none;
			}
		}
		.logout{
			font-size: 1.4rem;position: relative;margin-top: 60px;
			&:before{
				content:"";width: 50px;height: 1px;background: #777;
				position: absolute;left:calc(50% - 25px);top:-30px;
			}
			a{
				color: #ccc;
			}
		}
	}
	@include for-sp(){
		font-size: 1.5rem;
		li{margin-top: 10px;}
		.logout{
			font-size: 1.2rem;
		}
		
	}
}


article{
	width: 960px;margin: 0 auto;padding: 70px 0;
	@include for-tb(){
		width: 80%;
	}
	@include for-sp(){
		padding: 40px 0;
	}
}


// フッター
footer{
	padding: 100px 0 30px;
	@include for-sp(){
		padding: 40px 0 20px;
	}
}
.copyright{
	font-size: 1.0rem;line-height: 1;letter-spacing: 0.05em;display: block;text-align: center;
	a{
		color: #999;
	}
}
.copymark{
	font-family: Verdana,"Droid Sans";
}